import React from 'react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SlideCard from './SlideCard';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



const Slider = () => {
    const vehicles = [
        {
            name: "Versa",
            car_model_id: 26,
            car_model_year: 2024,
            // <br class='md:hidden'/> 
            description: "Bono de $1,000",
            legal: "Bono de $1,000 ($750 del distribuidor más $250 del concesionario) aplicable al pronto pago de todas las variantes Nissan Versa 2024. Ejemplo de bono para Versa 2024 VES-2403: MSRP de $22,155 menos $1,000 de bono, balance a financiar de $21,155. Ciertos términos, restricciones y condiciones aplican. No incluye seguros, tablilla, ACAA, gastos de registro ni accesorios. Ofertas publicadas no pueden combinarse con otras ofertas, descuentos o entre sí. Para más detalles sobre las ofertas aquí publicadas e información sobre especificaciones por modelo, disponibilidad de tecnologías, visita nissan.pr o los concesionarios Nissan autorizados participantes. Los precios ilustrados son los precios sugeridos por el manufacturero. MPG basado en la guía EPA de rendimiento de combustible. El rendimiento puede variar según hábitos de manejo y condiciones del camino. Oferta válida del 1 al 30 de abril del 2025.",
            cta1url: "https://es.nissan.pr/herramientas-de-compra/Prueba_de_manejo.html",
            cta1label: "Coordina una prueba de manejo",
            cta2url: "https://es.nissan.pr/herramientas-de-compra/Estimados.html",
            cta2label: "Solicita un estimado",
            image_desk: "/sliders/Slider-Versa-desktop.jpg",
            image_mob: "/sliders/Slider-Versa-mobile.jpg",
        },
          {
            name: "Sentra",
            car_model_id: 3558,
            car_model_year: 2024,

            description: "Bono de $1,500",
            legal: "Bono de $1,500 ($1,000 del distribuidor más $500 del concesionario) aplicable al pronto pago de todas las variantes Nissan Sentra 2024. Ejemplo de bono para Sentra 2024, SE-2401: MSRP de $25,713 menos $1,500 de bono, balance a financiar de $24,213. Ciertos términos, restricciones y condiciones aplican. No incluye seguros, tablilla, ACAA, gastos de registro ni accesorios. Ofertas publicadas no pueden combinarse con otras ofertas, descuentos o entre sí. Para más detalles sobre las ofertas aquí publicadas e información sobre especificaciones por modelo, disponibilidad de tecnologías, visita nissan.pr o los concesionarios Nissan autorizados participantes. Los precios ilustrados son los precios sugeridos por el manufacturero. MPG basado en la guía EPA de rendimiento de combustible. El rendimiento puede variar según hábitos de manejo y condiciones del camino. Oferta válida del 1 al 30 de abril del 2025.",
            cta1url: "https://es.nissan.pr/herramientas-de-compra/Prueba_de_manejo.html",
            cta1label: "Coordina una prueba de manejo",
            cta2url: "https://es.nissan.pr/herramientas-de-compra/Estimados.html",
            cta2label: "Solicita un estimado",
            image_desk: "/sliders/Sentra-2024-desk.png",
            image_mob: "/sliders/Sentra-2024-mob.png",
        },
    ];

    return (
        <Swiper
            id='slider'
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
        >

            {vehicles.map((vehicle, index) => (
                <SwiperSlide key={index}>
                    <SlideCard
                        carmodelid={vehicle.car_model_id}
                        carname={vehicle.name}
                        caryear={vehicle.car_model_year}
                        legal={vehicle.legal}
                        buttontext={vehicle.description}
                        ctaurl={vehicle.cta2url}
                        ctalabel={vehicle.cta2label}
                        image_desk={vehicle.image_desk}
                        image_mob={vehicle.image_mob}
                    />
                </SwiperSlide>
            ))}

        </Swiper >
    );
};

export default Slider;
